<template>
  <swiper :slides-per-view="1" navigation :pagination="{ clickable: true }" :clickable="true" :loop="true" :centerSlide="true" :modules="modules" @swiper="onSwiper">
    <swiper-slide v-for="(record, index) in records">
      <component :is="`Aside-${type}`" :record="record" />
    </swiper-slide>
  </swiper>
</template>

<script>
import AsideTip from './Aside-Tip.vue'
import AsideOffer from './Aside-Offer.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination } from 'swiper'

export default {
  name: 'Aside-Slider-Wrap',
  components: {
    AsideOffer,
    AsideTip,
    Swiper,
    SwiperSlide,
  },
  props: {
    records: Array,
    type: String,
    autoplayDelay: {
      type: Number,
      default: 0,
    },
    autoplayOffset: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    let swiperInstance = null
    const onSwiper = (swiper) => {
      swiperInstance = swiper

      if (props.autoplayDelay > 0) {
        setTimeout(() => {
          swiperInstance.params.autoplay = {
            delay: props.autoplayDelay,
            disableOnInteraction: true,
            pauseOnMouseEnter: true
          }
          swiperInstance.autoplay.start()
        }, props.autoplayOffset * 1000)
      }
    }
    const onSlideChange = () => {
      //console.log("slide change");
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay],
    }
  },
}
</script>
