<template>
  <a class="box box--offer box--tip" :href="record.link" :rel="record.nofollow ? 'nofollow' : 'follow'" :target="record.linksettings?.target">
    <span class="box__image">
      <span class="box__content">
        <div class="headline headline--3">
          {{ record.header }}
        </div>
        <div class="headline headline--4">
          {{ record.subline }}
        </div>
      </span>
      <figure v-if="record.backgroundimage.publicUrl && 1 === 2">
        <img :src="record.backgroundimage.publicUrl" />
      </figure>
    </span>
  </a>
</template>

<script>
export default {
  name: 'Aside-Tip',
  props: {
    record: Object,
  },
  mounted() {},
}
</script>

<style scoped></style>
