<template>
  <a class="box box--offer box--top" :class="record.iconSelector.slice(0, -4)" :href="record.link" :rel="record.nofollow ? 'nofollow' : 'follow'" :target="record.linksettings?.target">
    <span class="box__image">
      <span class="box__content">
        <div class="headline headline--3">
          {{ record.header }}
        </div>
      </span>
      <figure v-if="record.backgroundimage.publicUrl && 1 === 2">
        <img :src="record.backgroundimage.publicUrl" />
      </figure>
    </span>
    <div class="box__content">
      <div class="bodytext headline headline--4" v-if="record.subline">
        {{ record.subline }}
      </div>
      <div class="bodytext" v-if="!record.subline">
        <ul class="list list--hook">
          <li v-if="record.feature1">{{ record.feature1 }}</li>
          <li v-if="record.feature2">{{ record.feature2 }}</li>
          <li v-if="record.feature3">{{ record.feature3 }}</li>
        </ul>
      </div>
      <button class="btn btn--small">
        <span>{{ record.ctalabel ? record.ctalabel : 'zum Angebot' }}</span>
      </button>
    </div>
  </a>
</template>
<script>
export default {
  name: 'Aside-Offer',
  data() {
    return {
      icon: null,
    }
  },
  props: {
    record: Object,
  },
  mounted() {
    // get current domain with protocol
    const domain = window.location.protocol + '//' + window.location.host
    if (this.record.iconSelector) {
      fetch(`${domain}/typo3conf/ext/site_package/Resources/Public/Modules/Assets/icons/selectable_icons_be/${this.record.iconSelector}`)
        .then((response) => response.text())
        .then((svg) => {
          this.icon = svg
        })
    }
  },
  methods: {},
}
</script>

<style scoped></style>
