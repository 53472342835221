<template>
  <template v-if="currentRecords && apiDataReady && !smallscreen">
    <Teleport :to="`#aside-${type}`">
      <div class="headline headline--3">{{ headlineMobile }}</div>
      <aside-slider-wrap :records="currentRecords" :type="type" v-if="currentRecords.length > 1" :autoplayDelay="7000" :autoplayOffset="10" />
      <template v-for="(record, index) in currentRecords" v-if="currentRecords.length < 2">
        <component :is="`Aside-${type}`" :record="record" />
      </template>
    </Teleport>
  </template>
  <template v-if="currentRecords && apiDataReady">
    <Teleport :to="`#left-${type}`">
      <div class="headline headline--3">{{ headlineMobile }}</div>
      <aside-slider-wrap :records="currentRecords" :type="type" v-if="currentRecords.length > 1" :autoplayDelay="7000" />
      <template v-for="(record, index) in currentRecords" v-if="currentRecords.length < 2">
        <component :is="`Aside-${type}`" :record="record" />
      </template>
    </Teleport>
  </template>
  <template v-if="currentRecords && apiDataReady && smallscreen && responsivePositions.length > 0">
    <template v-for="position of responsivePositions" key="position">
      <Teleport :to="position">
        <div class="headline headline--3">{{ headlineMobile }}</div>
        <aside-slider-wrap :records="currentRecords" :type="type" v-if="currentRecords.length > 1" :autoplayDelay="7000" />
        <template v-for="(record, index) in currentRecords" v-if="currentRecords.length < 2">
          <component :is="`Aside-${type}`" :record="record" />
        </template>
      </Teleport>
    </template>
  </template>
  <template v-if="currentRecords && apiDataReady">
    <Teleport :to="`#aside-${type}-popup`">
      <div class="headline headline--3">{{ headlineMobile }}</div>
      <aside-slider-wrap :records="currentRecords" :type="type" v-if="currentRecords.length > 1" :autoplayDelay="7000" />
      <template v-for="(record, index) in currentRecords" v-if="currentRecords.length < 2">
        <component :is="`Aside-${type}`" :record="record" />
      </template>
    </Teleport>
  </template>
</template>
<script>
import AsideTip from './templateparts/Aside-Tip.vue'
import AsideOffer from './templateparts/Aside-Offer.vue'
import AsideSliderWrap from './templateparts/Aside-Slider-Wrap.vue'

export default {
  components: { AsideOffer, AsideTip, AsideSliderWrap },
  name: 'ac-aside-record',
  data() {
    return {
      json: [],
      expired: false,
      currentRecords: [],
      apiDataReady: false,
      recordstoshow: 0,
      expireInSeconds: 900,
      smallscreen: false,
      responsivePositions: [],
    }
  },
  props: {
    type: String,
    src: String,
    countMobile: Number,
    countDesktop: Number,
    expires: Number,
    headlineMobile: String,
    headlineDesktop: String,
    linkAllText: String,
    linkAllUrl: String,
  },
  mounted() {
    this.responsive().then(() => {
      this.expireInSeconds = 900
      document.querySelector('#offerbubble')?.classList.remove('is--hidden')
      if (this.src && this.type) {
        Promise.race([
          new Promise((resolve) => window.addEventListener('click', resolve, { once: true })),
          new Promise((resolve) => window.addEventListener('scroll', resolve, { once: true })),
          new Promise((resolve) => window.addEventListener('touchstart', resolve, { once: true })),
        ]).then(() => {
          this.loadApiData(this.src, this.type)
        })
      }
    })
  },
  methods: {
    init() {
      // if local storage has no entry for v-record-expire, create one
      if (!sessionStorage.getItem(`v2-${this.type}-expire`)) {
        this.setNewExpireDate()
        this.watchExpireDate()
      } else {
        this.watchExpireDate()
      }
      // if local storage has no entry for v-record-current
      if (!sessionStorage.getItem(`v2-${this.type}-current`)) {
        this.expired = true
      } else {
        this.currentRecords = JSON.parse(sessionStorage.getItem(`v2-${this.type}-current`))
        // randomize the order of the records
        this.currentRecords.sort(() => Math.random() - 0.5)
      }
    },
    loadApiData(url, type) {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.json = data[type]
            this.apiDataReady = this.checkJson()
            if (this.apiDataReady) {
              this.init()
            }
          }
        })
      return true
    },
    watchExpireDate() {
      // now check every 10 seconds if the expire date is reached
      setInterval(() => {
        let expireDate = new Date(sessionStorage.getItem(`v2-${this.type}-expire`))
        let now = new Date()
        if (now > expireDate) {
          this.expired = true
          this.setNewExpireDate()
        } else {
          this.expired = false
        }
      }, 10000)
    },
    setNewExpireDate() {
      // set expire date to + 30 seconds
      let expireDate = new Date()
      expireDate.setSeconds(expireDate.getSeconds() + this.expireInSeconds)
      // expireDate.setMinutes(expireDate.getMinutes() + 1);
      sessionStorage.setItem(`v2-${this.type}-expire`, expireDate)
    },
    checkJson() {
      // check if the json is available and hase min. 1 entry
      if (this.json && this.json.length > 0) {
        this.recordstoshow > this.json.length ? (this.recordstoshow = this.json.length) : this.recordstoshow
        return true
      } else {
        return false
      }
    },
    setNewCurrentTips() {
      const temp = []
      // get random items from json array and push them to temp array until recordstoshow is reached
      while (temp.length < this.recordstoshow) {
        let random = Math.floor(Math.random() * this.json.length)
        if (!temp.includes(this.json[random])) {
          temp.push(this.json[random])
        }
      }
      // randomize the temp array
      temp.sort(() => Math.random() - 0.5)
      this.currentRecords = temp
      // write currentRecords to local storage
      sessionStorage.setItem(`v2-${this.type}-current`, JSON.stringify(this.currentRecords))
    },

    async responsive() {
      this.responsivePositions = []
      // check if the window width is smaller than 1024px
      this.smallscreen = true
      this.smallscreen = document.documentElement.clientWidth < 1024
      console.log('smallscreen', this.smallscreen)
      this.recordstoshow = this.countMobile || 1
      // get all headline elements
      let countableHeadline = document.querySelectorAll('.headline span.headline-index')
      // remove first headline element from countableHeadline
      countableHeadline = Array.from(countableHeadline).slice(1)
      if (this.type === 'offer') {
        // keep only even elements
        countableHeadline = countableHeadline.filter((el, index) => {
          return index % 2 === 0
        })
      } else {
        // keep only odd elements
        countableHeadline = countableHeadline.filter((el, index) => {
          return index % 2 !== 0
        })
      }
      if (this.smallscreen) {
        if (countableHeadline.length > 0) {
          countableHeadline.forEach((el) => {
            // get the next outer element with class "section"
            let section = el.closest('.section')
            // place a div with class "target" at last child of section
            let target = document.createElement('div')
            target.classList.add('section')
            target.classList.add('iv')
            target.classList.add('target')
            target.classList.add('target-' + this.type)
            section.parentNode.insertBefore(target, section)
            this.responsivePositions = document.querySelectorAll('.target.target-' + this.type)
            // place the target before the section
          })
        }
      }
    },
  },
  watch: {
    expired() {
      if (this.expired) {
        this.setNewCurrentTips()
        this.expired = false
      }
    },
  },
}
</script>
