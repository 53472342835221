import { defineStore } from "pinia";

export const useAudioplayerStore = defineStore("audioplayer", {
    state: () => {
        return {
            welcomeToStore: "AudioplayerStore is working",
            gloabalHeader: null,
            gloabalText: null,
            audioFile: null,
            currentAudio: null,
            trackLength: null,
            currentTime: null,
            trackPosition: null,
            ciclePosition: null,
            isPlaying: false,
            isPaused: true,
            isEnded: false,
            isMuted: false,
            volume: 1,
            restTime: {
                minutes: null,
                seconds: null,
            },
            trackTime: {
                minutes: null,
                seconds: null,
            },
            visibility: {
                section: {
                    inViewport: true,
                },
                "ac-audioplayer": {
                    inBreakpoint: true,
                    inViewport: true,
                },
                "ac-audioplayernav": {
                    inBreakpoint: false,
                    inViewport: false,
                },
                "ac-audioplayercontent": {
                    inBreakpoint: false,
                    inViewport: false,
                },
            },
        };
    },
    actions: {
        checkAudioFile() {
            if (this.audioFile && this.audioFile.endsWith(".mp3")) {
            } else {
                this.audioFile = null;
                // console.log("No mp3 file set");
            }
        },

        playAudio() {
            this.checkAudioFile();
            // console.log("playAudio");
            if (this.currentAudio) {
                this.currentAudio.play();
            } else {
                this.currentAudio = new Audio(this.audioFile);
                this.currentAudio.play().then(() => {
                    // console.log("Audio played");
                });
                // get length of audio file
                this.currentAudio.addEventListener("loadedmetadata", () => {
                    this.trackCalculation();
                });
                this.currentAudio.addEventListener("timeupdate", () => {
                    this.trackCalculation();
                });
                // track if audio is playing
                this.currentAudio.addEventListener("playing", () => {
                    this.isPlaying = true;
                    this.isPaused = false;
                    this.isEnded = false;
                });
                // track if audio is paused
                this.currentAudio.addEventListener("pause", () => {
                    this.isPlaying = false;
                    this.isPaused = true;
                    this.isEnded = false;
                });
                // track if audio is ended
                this.currentAudio.addEventListener("ended", () => {
                    this.isPlaying = false;
                    this.isPaused = false;
                    this.isEnded = true;
                });
            }
        },
        stopAudio() {
            // console.log("stopAudio");
            if (this.currentAudio) {
                this.currentAudio.pause();
            }
        },
        toggleAudio() {
            // console.log("toggleAudio");
            if (this.isPlaying) {
                this.stopAudio();
            } else {
                this.playAudio();
                document.documentElement.classList.add("audio--started");
            }
        },

        toggleMute() {
            if (this.currentAudio) {
                this.currentAudio.muted = !this.currentAudio.muted;
                this.isMuted = this.currentAudio.muted;
            }
        },
        volumeChange(value) {
            // console.log(value);
            // calaculate the percentage of the volume slider
            const percentage = (value.offsetX / value.target.offsetWidth) * 100;
            // round the percentage to the nearest 20
            const roundedPercentage = Math.round(percentage / 20) * 20;
            // multiply the rounded percentage by 0.01 to get the volume
            this.volume = roundedPercentage / 100;
            // set the volume of the audio
            this.currentAudio.volume = this.volume;
            // console.log(this.currentAudio.volume);
        },
        jumpToPosition(value) {
            // console.log(value);
            // calaculate the percentage of the volume slider
            const percentage = (value.offsetX / value.target.offsetWidth) * 100;
            // round the percentage to the nearest 20
            const roundedPercentage = Math.round(percentage / 5) * 5;
            // multiply the rounded percentage by 0.01 to get the volume
            this.trackPosition = roundedPercentage / 100;
            // set the volume of the audio
            if (this.currentAudio) {
                this.currentAudio.currentTime =
                    this.trackPosition * this.trackLength;
                // console.log(this.currentAudio.currentTime);
            }
        },
        trackCalculation() {
            // console.log("trackCalculation");
            if (this.currentAudio) {
                // set root variable for css
                document.documentElement.style.setProperty(
                    "--progressbar",
                    this.trackPosition + "%"
                );
                // console.log("------------------");
                // tracklength in seconds
                this.trackLength = Math.round(this.currentAudio.duration);
                // console.log("Audio length: " + this.trackLength);
                // get current time and round it with one number after comma
                this.currentTime = this.currentAudio.currentTime.toFixed(2);
                // console.log("Audio time: " + this.currentTime);
                // calculate track position in %
                this.trackPosition = (
                    (this.currentAudio.currentTime /
                        this.currentAudio.duration) *
                    100
                ).toFixed(2);
                // console.log("trackPosition: " + this.trackPosition + "%");
                // calcualte rest time
                const restTime = (this.trackLength - this.currentTime).toFixed(
                    2
                );
                // split rest time in minutes and seconds are lower than 10 add a 0
                this.restTime.minutes = Math.floor(restTime / 60);
                this.restTime.seconds = Math.floor(restTime % 60);
                // if this.restTime.minutes is negative set it to 0
                if (this.restTime.minutes < 0) {
                    this.restTime.minutes = 0;
                }
                // if this.restTime.seconds is negative set it to 0
                if (this.restTime.seconds < 0) {
                    this.restTime.seconds = 0;
                }
                if (this.restTime.seconds < 10) {
                    this.restTime.seconds = "0" + this.restTime.seconds;
                }
                /*console.log(
                    "restTimeM: " +
                        this.restTime.minutes +
                        "Min" +
                        this.restTime.seconds +
                        "Sec"
                );*/
                const timebase = 115;
                const timeprogress = Math.round(
                    (this.currentTime / this.trackLength) * timebase
                );
                this.ciclePosition = timeprogress * -1;

                // split track time in minutes and seconds
                this.trackTime.minutes = Math.floor(this.trackLength / 60);
                this.trackTime.seconds = Math.floor(this.trackLength % 60);
                if (this.trackTime.seconds < 10) {
                    this.trackTime.seconds = "0" + this.trackTime.seconds;
                }
            }
        },
    },
});
