import { defineCustomElement, h, createApp, getCurrentInstance } from "vue";

import audioplayer from "./ac-audioplayer.ce.vue";
import audioplayernav from "./ac-audioplayernav.ce.vue";
import audioplayercontent from "./ac-audioplayercontent.ce.vue";
import asideTipp from "./ac-aside-element.ce.vue";
import flyout from "./ac-flyout.ce.vue";
import { createPinia } from "pinia";

const pinia = createPinia();
export const defineCustomElementWrapped = (component, { plugins = [] } = {}) =>
    defineCustomElement({
        styles: component.styles,
        props: component.props,
        setup(props, { slots }) {
            const app = createApp();
            plugins.forEach((plugin) => {
                app.use(plugin);
            });
            const inst = getCurrentInstance();
            Object.assign(inst.appContext, app._context);
            Object.assign(inst.provides, app._context.provides);
            return () =>
                h(component, {
                    ...props,
                    ...slots,
                });
        },
    });

customElements.define(
    "ac-audioplayer",
    defineCustomElementWrapped(audioplayer, { plugins: [pinia] })
);
customElements.define(
    "ac-audioplayernav",
    defineCustomElementWrapped(audioplayernav, { plugins: [pinia] })
);
customElements.define(
    "ac-audioplayercontent",
    defineCustomElementWrapped(audioplayercontent, { plugins: [pinia] })
);
customElements.define(
    "ac-aside-element",
    defineCustomElementWrapped(asideTipp, { plugins: [pinia] })
);
customElements.define(
    "ac-flyout",
    defineCustomElementWrapped(flyout, { plugins: [pinia] })
);
