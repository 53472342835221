<template>
  <div
    class="audio audio--intro"
    v-if="audioFile && visibility['ac-audioplayer'].inBreakpoint"
  >
    <div class="audio__content">
      <div class="headline headline--4" v-if="gloabalHeader">
        {{ gloabalHeader }}
      </div>
      <div class="bodytext" v-if="gloabalText" v-html="gloabalText"></div>
      <div
        class="player"
        :class="
          isPlaying ? 'is-playing' : null || isPaused ? 'is-paused' : null
        "
      >
        <div class="timeline" @click="jumpToPosition($event)">
          <div
            class="progress progressbar"
            :style="`width:${trackPosition}%`"
          ></div>
        </div>
        <div class="controls">
          <div
            class="toggle-play"
            @click="toggleAudio()"
            :class="
              isPlaying
                ? 'pause'
                : null || isPaused
                ? 'play'
                : null || isEnded
                ? 'play'
                : null
            "
          >
            <svg
              class="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
            >
              <circle class="bg" cx="20" cy="20" r="20"></circle>
              <path class="icon-play" d="M28,20l-12,6.93V13.07l12,6.93Z"></path>
              <path
                class="icon icon-pause"
                d="M15,13h4v14h-4V13Zm6,0h4v14h-4V13Z"
              ></path>
              <circle
                class="progress"
                cx="20"
                cy="20"
                r="18.4"
                :style="`stroke-dashoffset: ${ciclePosition};`"
              ></circle>
            </svg>
          </div>
          <div class="time" v-if="restTime.minutes || restTime.seconds">
            <div class="current">
              {{ restTime.minutes }}:{{ restTime.seconds }}
            </div>
          </div>

          <div class="volume-container">
            <div class="volume-button" @click="toggleMute()">
              <div class="volume" :class="isMuted ? 'volume-off' : 'volume-on'">
                <svg
                  class="icon-volumeOn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  <path
                    d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"
                  ></path>
                </svg>
                <svg
                  class="icon-volumeOff"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                  <line x1="23" y1="9" x2="17" y2="15"></line>
                  <line x1="17" y1="9" x2="23" y2="15"></line>
                </svg>
              </div>
            </div>
            <div class="volume-slider" @click="volumeChange($event)">
              <div
                class="volume-percentage"
                :style="`width: ${volume * 100}%`"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { useAudioplayerStore } from "./stores/store-audioplayer";

export default {
  name: "ad-audioplayer",
  props: {
    track: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapWritableState(useAudioplayerStore, [
      "audioFile",
      "trackLength",
      "currentTime",
      "trackPosition",
      "restTime",
      "isPlaying",
      "isPaused",
      "isStopped",
      "isEnded",
      "ciclePosition",
      "isMuted",
      "volume",
      "trackTime",
      "gloabalHeader",
      "gloabalText",
      "visibility",
    ]),
  },
  methods: {
    ...mapActions(useAudioplayerStore, [
      "stopAudio",
      "playAudio",
      "toggleAudio",
      "checkAudioFile",
      "toggleMute",
      "volumeChange",
      "jumpToPosition",
    ]),
    visibilityHandler() {
      // check if viewport is larger than 1024px
      if (window.innerWidth >= 1024) {
        this.visibility["ac-audioplayer"].inBreakpoint = true;
        this.visibility["ac-audioplayercontent"].inBreakpoint = false;
      } else {
        this.visibility["ac-audioplayer"].inBreakpoint = false;
        this.visibility["ac-audioplayercontent"].inBreakpoint = true;
      }
      // check on resize
      window.addEventListener("resize", () => {
        if (window.innerWidth >= 1024) {
          this.visibility["ac-audioplayer"].inBreakpoint = true;
          this.visibility["ac-audioplayercontent"].inBreakpoint = false;
        } else {
          this.visibility["ac-audioplayer"].inBreakpoint = false;
          this.visibility["ac-audioplayercontent"].inBreakpoint = true;
        }
      });
      // add intersection observer to check if element is in viewport
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.visibility[entry.target.localName].inViewport =
              entry.isIntersecting;
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        }
      );
      observer.observe(document.querySelector("ac-audioplayer"));
      observer.observe(document.querySelector("ac-audioplayercontent"));
      observer.observe(document.querySelector("section.intro"));
    },
  },
  mounted() {
    this.audioFile = this.track;
    this.checkAudioFile();
    this.headline ? (this.gloabalHeader = this.headline) : null;
    this.text ? (this.gloabalText = this.text) : null;
    this.visibilityHandler();
  },
  watch: {
    visibility: {
      handler: function (val) {
        if (
          !val["ac-audioplayer"].inViewport &&
          val["ac-audioplayer"].inBreakpoint &&
          !val["section"].inViewport
        ) {
          document.documentElement.classList.add("sticky--audio");
        } else if (
          !val["ac-audioplayercontent"].inViewport &&
          val["ac-audioplayercontent"].inBreakpoint &&
          !val["section"].inViewport
        ) {
          document.documentElement.classList.add("sticky--audio");
        } else {
          document.documentElement.classList.remove("sticky--audio");
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@use "../../../../../Modules/GlobalTemplates/Style/Components/vue-audioplayer";

.audio .timeline {
  mask-image: url("assets/audio_wave.svg");
}
.audio.is-playing .timeline {
  mask-image: url("assets/audio_wave_ani.svg");
}
</style>
