<template>
  <Teleport v-if="apiDataReady && apiData" to=".flyout">
    <button class="btn btn--close" title="Close Flyout" aria-label="Close Flyout">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </button>
    <div class="title">Unser Partnerangebot</div>
    <div class="flyout__inner btn btn--big bg-petrol font-white">
      <div class="content__wrap">
        <div class="content content--initial">
          <a class="label" :href="apiData.buttonlink" :rel="apiData.nofollow ? 'nofollow' : 'follow'" :target="apiData.linksettings.target">{{ apiData.title }} </a>
          <span class="icon icon--expand">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" stroke="none">
              <path class="circle" d="M16,0h0c8.84,0,16,7.16,16,16h0c0,8.84-7.16,16-16,16h0C7.16,32,0,24.84,0,16H0C0,7.16,7.16,0,16,0Z" />
              <path
                fill="#f1f3f4"
                d="M22.23,8l-7.94,1.99c-.17,.04-.32,.13-.44,.26-.12,.12-.21,.27-.26,.44-.05,.17-.05,.34,0,.51,.04,.17,.13,.32,.25,.45l5.95,5.95c.12,.12,.28,.21,.45,.25,.17,.04,.34,.04,.51,0,.17-.05,.32-.13,.44-.26,.12-.12,.21-.28,.26-.44l1.99-7.94c.04-.17,.04-.34,0-.5-.05-.16-.13-.31-.25-.43-.12-.12-.27-.21-.43-.25-.16-.05-.34-.05-.5,0Zm-12.84,15.61l7.94-1.99c.17-.04,.32-.13,.44-.25,.12-.12,.21-.27,.26-.44,.05-.17,.05-.34,0-.51-.04-.17-.13-.32-.25-.45l-5.95-5.95c-.12-.12-.28-.21-.45-.25-.17-.04-.34-.04-.51,0-.17,.05-.32,.13-.44,.26s-.21,.28-.26,.44l-1.99,7.94c-.04,.17-.04,.34,0,.5s.13,.31,.25,.43c.12,.12,.27,.21,.43,.25,.16,.05,.34,.05,.5,0Z"
              />
            </svg>
          </span>
        </div>
        <a class="content content--expand" :href="apiData.buttonlink" :rel="apiData.nofollow ? 'nofollow' : 'follow'" :target="apiData.linksettings.target">
          <div class="icon">
            <inline-svg :src="'/typo3conf/ext/site_package/Resources/Public/Modules/Assets/icons/selectable_icons_flyout/' + apiData.iconSelector"></inline-svg>
          </div>
          <div class="bodytext">
            <ul class="list list--hook">
              <li v-if="apiData.feature1">{{ apiData.feature1 }}</li>
              <li v-if="apiData.feature2">{{ apiData.feature2 }}</li>
              <li v-if="apiData.feature3">{{ apiData.feature3 }}</li>
              <li v-if="apiData.feature4">{{ apiData.feature4 }}</li>
            </ul>
          </div>
          <div class="btn">
            <span class="label">{{ apiData.buttonlabel }}</span>
          </div>
        </a>
      </div>
    </div>
  </Teleport>
  <Teleport v-if="apiDataReady && apiData" to="#left-flyout">
    <div class="box box--offer box--partner">
      <div class="box__content">
        <div class="icon">
          <inline-svg :src="'/typo3conf/ext/site_package/Resources/Public/Modules/Assets/icons/selectable_icons_flyout/' + apiData.iconSelector"></inline-svg>
        </div>
        <div class="headline headline--3">{{ apiData.title }}</div>
        <div class="bodytext">
          <ul class="list list--hook">
            <li v-if="apiData.feature1">{{ apiData.feature1 }}</li>
            <li v-if="apiData.feature2">{{ apiData.feature2 }}</li>
            <li v-if="apiData.feature3">{{ apiData.feature3 }}</li>
            <li v-if="apiData.feature4">{{ apiData.feature4 }}</li>
          </ul>
        </div>
        <a class="btn btn--small" :href="apiData.buttonlink" :rel="apiData.nofollow ? 'nofollow' : 'follow'" :target="apiData.linksettings.target">
          <span>{{ apiData.buttonlabel }}</span>
        </a>
      </div>
    </div>
  </Teleport>
</template>
<script>
import InlineSvg from 'vue-inline-svg'

export default {
  components: { 'inline-svg': InlineSvg },
  name: 'ac-flyout',
  data() {
    return {
      apiData: {},
      apiDataReady: false,
    }
  },
  props: {
    api: {
      type: String,
    },
  },
  async mounted() {
    Promise.race([
      new Promise((resolve) => window.addEventListener('click', resolve, { once: true })),
      new Promise((resolve) => window.addEventListener('scroll', resolve, { once: true })),
      new Promise((resolve) => window.addEventListener('touchstart', resolve, { once: true })),
    ]).then(async () => {
      await this.getApi()
    })
  },
  methods: {
    async getApi() {
      fetch(this.api)
        .then((response) => response.json())
        .then((data) => {
          this.apiData = data.flyout
          this.apiDataReady = true
          // randomly select one of the flyouts
          this.apiData = this.apiData[Math.floor(Math.random() * this.apiData.length)]
          //console.log(this.apiData);
          setTimeout(() => {
            this.flyoutJS()
            this.menuJS()
          }, 100)
        })
    },
    menuJS() {
      const body = document.querySelector('body')
      const stage = document.querySelector('section.intro')

      const offersLeft = document.querySelector('#offers--left')
      const toggleButton = document.querySelector('#offers--left .btn--show')
      const closeButton = document.querySelector('#offers--left .btn--close')
      const dimm = document.querySelector('.offers--left__close')

      if (toggleButton) {
        window.addEventListener('scroll', function () {
          const scrollY = window.scrollY
          if (stage) {
            const stageHeight = stage.clientHeight
            if (scrollY > stageHeight) {
              offersLeft.classList.add('is--visible')
            } else {
              offersLeft.classList.remove('is--visible')
            }
          } else {
            offersLeft.classList.add('is--visible')
          }
        })
        body.classList.add('has--offersLeft')
        toggleButton.addEventListener('click', () => {
          offersLeft.classList.toggle('is--open')
        })
        const close = () => {
          offersLeft.classList.remove('is--open')
        }
        closeButton.addEventListener('click', () => {
          close()
        })
        dimm.addEventListener('click', () => {
          close()
        })
      }
    },
    flyoutJS() {
      const delay = 20000
      document.documentElement.style.setProperty('--flyoutDelay', delay / 1000 + 's')

      const controls = document.querySelector('.page__controls')
      if (controls === null) {
        console.error("Element with class 'page__controls' not found.")
        return
      }

      const flyout = controls.querySelector('.flyout')
      if (flyout === null) {
        console.error("Element with class 'flyout' not found.")
        return
      }

      const flyout_close = flyout.querySelector('.btn--close')
      const flyout_initial = flyout.querySelector('.content--initial')
      const flyout_open = flyout_initial.querySelector('.content--initial .icon--expand')

      // Flyout
      const initFlyout = () => {
        if (window.matchMedia('(min-width: 1280px)').matches) {
          flyout.style.setProperty('--flyoutWidth', flyout.clientWidth / 10 + 'rem')
          flyout.style.setProperty('--initial', flyout_initial.clientWidth / 10 + 'rem')
          let sessionStatus = sessionStorage.getItem('closed')
          if (sessionStatus == 1) {
            flyout.classList.add('session--closed')
          }
          flyout_close.addEventListener('click', function () {
            flyout.classList.remove('is--expanded')
            flyout.classList.add('is--closed')
            sessionStorage.setItem('closed', '1')
            flyout.classList.add('session--closed')
          })
          flyout_open.addEventListener('click', function () {
            flyout.classList.add('is--expanded')
            flyout.classList.remove('is--closed')
            if (flyout.classList.contains('session--closed')) {
              sessionStorage.setItem('closed', '0')
              flyout.classList.remove('session--closed')
            }
          })

          const getScrollPos = () => {
            let toc = document.querySelector('.col--aside .table_of_contents')
            if (toc) {
              let tocHeight = toc.clientHeight
              let tocOffset = toc.getBoundingClientRect().top
              let showToc = Math.round(tocHeight + tocOffset)
              window.addEventListener(
                'scroll',
                () => {
                  if (window.scrollY > tocOffset) {
                    flyout.classList.add('is-visible')
                    if (!flyout.classList.contains('is--closed')) {
                      setTimeout(() => {
                        flyout.classList.add('is--expanded')
                      }, delay)
                    } else {
                      flyout.classList.remove('is--expanded')
                    }
                  } else {
                    flyout.classList.remove('is-visible')
                  }
                },
                { passive: true }
              )
            }
          }

          setTimeout(() => {
            flyout.classList.add('init')
            getScrollPos()
          }, 500)

          return false
        }
      }
      if (flyout) {
        setTimeout(() => {
          initFlyout()
        }, 100)
      }
    },
  },
}
</script>

<style lang="scss"></style>
